// Main button for creating a number.
a.create-number,
button.create-number {
    display: inline-block;
    margin: 0 auto;
    padding: 0 rem(30);
    // min-width: rem(140);
    text-decoration: none;
    // font-size: rem(18);
    text-align: center;
    cursor: pointer;

    &:hover,
    &:focus {
        background-color: dodgerblue;
        color: white;
    }
}