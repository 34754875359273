// 
// Basic popover component.
//

.popover {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 20;

    >div {
        max-width: 800px;
        width: calc(100vw - 4rem);
        margin: 0 auto;
    }

    .head {
        height: rem(100);
        display: flex;
        flex-direction: row;

        >div,
        .left,
        .right {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .left {
            width: 20%;
            flex-grow: 0;
        }

        .middle {
            flex-basis: 60%;
            flex-grow: 0;
            text-align: center;
            justify-content: center;
            // Title goes here.
            font-size: rem(22);
            font-weight: bold;
        }

        .right {
            width: 20%;
            flex-grow: 0;
        }

        // Others.

        // Cancel link.
        .cancel {

            height: rem(50);
            width: rem(50);
            display: block;
            //background-color: $color-button-background;
            text-align: center;
            text-decoration: none;
            color: $color-button-text;
            position: relative;

            // Add a cross.
            &:before,
            &:after {
                display: block;
                content: '';
                width: rem(30);
                height: 4px;
                background-color: $color-button-text;
                transform-origin: 0, 0;
                position: absolute;
                top: rem(23);
                left: rem(10);
                transform: translate(-50%, -50%);
            }

            &:before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }

            >span {
                display: none;
            }

            // Small.
            @media screen and (min-width: rem($bp-sm)) {

                // Remove the cross.
                &:before,
                &:after {
                    display: none;
                }

                >span {
                    display: inline;
                }

                // Other styles.
                height: rem(40);
                width: auto;
                line-height: rem(40);
                border: 4px solid $color-button-text;
                border-radius: rem(25);
                padding: 0 rem(10);
                font-size: rem(18);
            }

            // Medium.
            @media screen and (min-width: rem($bp-md)) {}


        }
    }
}