// Header component.
#header {
    height: rem(100);
    display: flex;
    flex-direction: row;

    >div,
    .left,
    .right {
        height: 100%;
    }

    .left,
    .right {
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .left {
        margin-left: rem(20);
    }

    .right {
        margin-right: rem(20);
        flex-grow: 1;
        flex-shrink: 0;
    }

    // Default (small).
    @media screen and (min-width: rem($bp-md)) {}

    // Medium.
    // Large.
}