// Generic form field.

// Make sure IOS doesn't zoom when form inputs are in focus.
@media screen and (-webkit-min-device-pixel-ratio:0) {

    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: #eee;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
}

.form-container {
    max-width: rem(260);
    margin: 0 auto;
}

.field-container {
    .label-container {

        height: rem(40);
        line-height: rem(40);

        >.inner,
        >label {
            font-weight: bold;
        }
    }

    .input-container {
        margin-bottom: rem(20);

        >.inner {
            min-height: rem(50);
            background-color: #EEE;
            position: relative;
            border-radius: rem(10);

            >input {
                display: block;
                position: absolute;
                top: rem(5);
                left: rem(5);
                width: calc(100% - #{rem(10)});
                height: rem(40);

                margin: 0;
                border: 0;
                padding: 0;
                background-color: transparent;
                font-family: inherit;
                font-size: rem(22);
                color: black;
                text-indent: rem(15);

                &:focus {
                    outline: none;
                }
            }
        }
    }
}