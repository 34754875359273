// Styles for card components.
.card {
    background-color: #EEE;
    border-radius: rem(10);
    margin: 0 0 rem(15);
    padding: rem(15);
    min-height: rem(120);

    .card-heading {
        font-weight: bold;
        font-size: rem(22);
        line-height: 1.25em;
        margin-bottom: rem(8);
    }

    .card-text {
        line-height: 1.5em;
        margin-bottom: rem(8);
    }

    // Smaller.
    @media screen and (min-width: rem($bp-sm)) {}

    // Medium.
    @media screen and (min-width: rem($bp-md)) {}
}