// Generic button styles.
.button {
    // Resets.
    /*
    background: none;
    font-family: inherit;
    margin: 0;
    border: none;
    padding: 0;
    font-size: inherit;
    */

    // display: block;
    // height: rem(40);
    height: 50px;
    height: rem(40);
    // line-height: rem(40);
    background-color: $color-button-background;
    // min-width: rem(50);
    padding: 0 rem(10);
    text-align: center;
    border: 4px solid $color-button-text;
    text-decoration: none;
    color: $color-button-text;
    border-radius: rem(25);
    font-size: rem(18);

    //
    // Variants.
    //

    // Size
    &.small {}

    &.small {}

    &.large {
        font-size: rem(22);
        height: rem(60);
        line-height: rem(60);

        &.outlined {
            line-height: rem(52);
        }

        &.solid {
            border-width: 0;
            line-height: rem(60);
            border-radius: rem(30);
        }
    }

    // Style
    &.outlined {}

    &.solid {}

    // Tone
    &.light {}

    &.dark {
        background-color: black;
        color: white;
    }

    // Width
    &.full-width {
        width: 100%;
    }


}